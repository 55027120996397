<template>

	<!-- Settings Drawer -->
  <a-drawer
		class="settings-drawer"
		:closable="false"
		:visible="showCustomerDrawer"
		width="490"
		:getContainer="() => wrapper"
		@close="$emit('toggleCustomerDrawer', false)"
	>

		<!-- Settings Drawer Close Button -->
<!--		<a-button type="link" class="btn-close" @click="$emit('toggleCustomerDrawer', false)">-->
<!--			<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">-->
<!--				<g id="close" transform="translate(0.75 0.75)">-->
<!--					<path id="Path" d="M7.5,0,0,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>-->
<!--					<path id="Path-2" data-name="Path" d="M0,0,7.5,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>-->
<!--				</g>-->
<!--			</svg>-->
<!--		</a-button>-->
		<!-- / Settings Drawer Close Button -->
		
		<!-- Settings Drawer Content -->
    <div class="back_img" style="background:url('images/service-bg.png')"></div>
    <div class="drawer-content">
      <h6>如果您有任何问题，请联系下方客服</h6>
      <div class="box">
        <div class="item">
          <div class="left">
            <div class="info">
              <h6>{{ nickname }}</h6>
            </div>
            <div class="info">
              <img src="/images/logos/wechat.svg" class="icon" style="height: 20px;"/>
              <div class="desc">{{ wx }}</div>
            </div>
            <div class="info">
              <svg t="1703669911211" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="18" height="18">
                <path d="M1024 512c0 281.6-230.4 512-512 512S0 793.6 0 512 230.4 0 512 0s512 230.4 512 512z" fill="#58a9e3" p-id="880"></path>
                <path d="M697.14 767.18l100.556-489.062c10.07-41.199-15.087-61.78-45.26-51.507l-593.58 231.663c-40.277 15.463-40.277 41.2-5.051 51.473l150.937 51.507 357.172-231.663c15.087-10.308 30.173-5.154 20.138 5.12L395.264 602.453l-10.07 159.608c15.088 0 25.157-5.154 30.175-15.463l75.468-72.055L646.793 792.95c25.156 15.429 45.26 5.12 50.312-25.736z" fill="#ffffff" p-id="881"></path>
              </svg>
              <a class="desc" :href="telegramLink" target="_blank">{{ telegram }}</a>
            </div>
          </div>
<!--          <div class="right">-->
<!--            <div class="tips">扫一扫添加客服</div>-->
<!--            <img class="qr" src="/images/qr.png" />-->
<!--          </div>-->
          <div class="right">
            <a-button type="primary" href="https://xwvmohge80.feishu.cn/wiki/WHcEwy0dQinSMIkms9LcrVr7nUF?from=from_copylink" block target="_blank">
              帮助文档
            </a-button>
            <a-button type="primary" href="https://txc.qq.com/products/647309" block target="_blank" style="margin-top: 10px">
              产品社区
            </a-button>
          </div>
        </div>
      </div>
    </div>

		<!-- / Settings Drawer Content -->

	</a-drawer>
	<!-- / Settings Drawer -->

</template>

<script>
import http from "../../utils/http";
import store from "@/store";

	export default ({
		props: {
			// Settings drawer visiblility status.
      showCustomerDrawer: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				// The wrapper element to attach dropdowns to.
				wrapper: document.body,

        wx: '', // 微信
        telegram: '', // telegram
        nickname: '', // 昵称

			}
		},
		mounted: function(){
			// Set the wrapper to the proper element, layout wrapper.
			this.wrapper = document.getElementById('layout-dashboard') ;
		},
    computed: {
      telegramLink() {
        // 生成完整的 Telegram 链接
        return `https://t.me/${this.telegram}`;
      }
    },
    created() {
      this.getConnect();
    },
    methods: {
      async getConnect() {
        const response = await http.get('/api/connect/?token=' + localStorage.getItem("token"));
        if (response.data.code === 0) {
          this.nickname = response.data.data.nickname;
          this.wx = response.data.data.wx;
          this.telegram = response.data.data.telegram;
        } else {
          return;
        }
      }
    }
	})

</script>
